const config = require('../../config');
const strings = config.strings;
const z2hApp = require('./z2hApp');
const showToastMessage = require('../action_helpers/showToastMessage');

// These IDs are configured in the Apple App Store Connect portal and the Google Developer
// Console. They have been configured so that they are the same for both platforms.
const ANNUAL_SUBSCRIPTION_ID = 'com.forghetti.forghettiapp.annualsubscription';
const MONTHLY_SUBSCRIPTION_ID = 'com.forghetti.forghettiapp.monthlysubscription';
const LIFETIME_SUBSCRIPTION_ID = 'com.forghetti.forghettiapp.lifetime';
//!!! Android only, weekly subscription for testing purposes only
//const LIFETIME_SUBSCRIPTION_ID = 'com.forghetti.forghettiapp.testweeklysubscription';

let initialized = false;

let store, ProductType, Platform, TransactionState;
let canUpgrade = false;

//Test prods can be used in desktop mode for testing.
const testProds = [
  {
    id: MONTHLY_SUBSCRIPTION_ID,
    price: '£1',
    billingPeriodUnit: 'month',
    active: true,
    alias: 'Monthly plan',
    state: 'valid',
  },
  {
    id: ANNUAL_SUBSCRIPTION_ID,
    price: '£10',
    billingPeriodUnit: 'year',
    active: false,
    alias: 'Yearly plan',
    state: 'valid',
  },
  {
    id: LIFETIME_SUBSCRIPTION_ID,
    price: '£100',
    durabillingPeriodUnittion: '',
    active: false,
    alias: 'One-off purchase',
    state: 'valid',
  },
];
// UI update functions ================================= //

//Each product has a content box displaying what the user can get
const productDetails = () => [
  {
    id: MONTHLY_SUBSCRIPTION_ID,
    header: strings.PREMIUM_MONTHLY_HEADER(),
    details: strings.PREMIUM_MONTHLY_LIST(),
    billingRecurrence: strings.MONTHLY(),
    tabHeader: strings.PREMIUM_MONTHLY_ALIAS(),
  },
  {
    id: ANNUAL_SUBSCRIPTION_ID,
    header: strings.PREMIUM_YEARLY_HEADER(),
    details: strings.PREMIUM_YEARLY_LIST(),
    billingRecurrence: strings.YEARLY(),
    tabHeader: strings.PREMIUM_ANNUAL_ALIAS(),
  },
  {
    id: LIFETIME_SUBSCRIPTION_ID,
    header: strings.PREMIUM_LIFETIME_HEADER(),
    details: strings.PREMIUM_LIFETIME_LIST(),
  },
];

//Each product has a button, if the button is valid we can purcahse
//Otherwise we can't
const buttonDetails = () => [
  {
    state: 'valid',
    text: strings.PREMIUM_PURCHASE_BUTTON(),
    action: 'purchaseLegal',
  },
  {
    state: 'invalid',
    text: strings.PREMIUM_PURCHASE_BUTTON_NA(),
    action: '',
  },
  {
    state: 'owned',
    text: strings.PREMIUM_PURCHASE_BUTTON_OWNED(),
    action: '',
  },
];

//The tab along the top, with the price displayed
const premiumTab = (product) => ({
  template: 'block-premium-tab',
  selector: '',
  attributes: [
    { selector: '', type: 'data-id', content: product.id },
    //{ selector: '', type: 'class', content: product.active && 'premium__tab--active' },

    { selector: '.premium__tab-title', type: 'innerText', content: product.tabHeader },
    {
      selector: '.premium__tab-price',
      type: 'innerText',
      content: product.pricing.price || strings.PREMIUM_PRICE_NONE(),
    },
    {
      selector: '.premium__tab-duration',
      type: 'innerText',
      content:
        product.pricing.billingPeriod === 'P1Y'
          ? '/' + strings.YEARLY()
          : product.pricing.billingPeriod === 'P1M'
          ? '/' + strings.MONTHLY()
          : '',
    },
    window.state.premiumActiveTab === product.id
      ? {
          selector: '',
          type: 'class',
          content: 'premium__tab--active',
        }
      : {},
  ],
});
//Content box header
const premiumContent = (detail) => ({
  template: 'block-premium-content',
  selector: '',
  attributes: [
    { selector: '.premium__tab-content-box h2', type: 'innerText', content: detail.header },
    {
      selector: '',
      type: 'class',
      content: window.state.premiumActiveTab === detail.id && 'premium__tab-content--active',
    },
  ],
});

//Content box header
const comingSoon = (detail) => ({
  template: 'block-premium-content',
  selector: '',
  attributes: [{ selector: '.premium__tab-content-box h2', type: 'innerText', content: strings.COMING_SOON() }],
});
//Purchase Button
const premiumButton = (data) => ({
  template: 'block-premium-button',
  selector: '',
  attributes: [
    { selector: 'button', type: 'class', content: 'cta-button' },
    { selector: 'button', type: 'innerText', content: data.text },
    { selector: 'button', type: 'data-actionclick', content: data.action },
    { selector: 'button', type: 'data-id', content: data.id },
  ],
});
//Whilst the store is approving the sale
//Whilst the store is approving the sale
const premiumRegistered = (_) => ({
  template: 'block-lochy',
  selector: '',
  attributes: [
    { selector: 'img', type: 'src', content: 'img/lochy/01-Lochy-Waving.gif' },
    { selector: '', type: 'class', content: 'no-border' },
  ],
});

const premiumApproved = (_) => ({
  template: 'block-lochy',
  selector: '',
  attributes: [
    { selector: 'img', type: 'src', content: 'img/lochy/11-Lochy-Confetti.gif' },
    { selector: '', type: 'class', content: 'no-border' },
  ],
});
//If the store is owned
const premiumOwned = (_) => ({
  template: 'block-lochy',
  selector: '',
  attributes: [
    { selector: 'img', type: 'src', content: 'img/lochy/11-Lochy-Confetti.gif' },
    { selector: '', type: 'class', content: 'no-border' },
  ],
});

//Build the html for a premium tab
const createPremiumTab = (product) => {
  if (!window.state.premiumActiveTab) {
    window.state.premiumActiveTab = ANNUAL_SUBSCRIPTION_ID;
  }
  const pTab = premiumTab(product);
  const pItem = z2hApp.constructBlock(pTab);
  return pItem;
};

//Build the html for approved lochy
const createPremiumApproved = (_) => {
  const title = {
    template: 'block-info-text',
    selector: '',
    attributes: [
      { selector: '', type: 'innerHTML', content: strings.PREMIUM_LOADING_APPROVED() },
      { selector: '', type: 'class', content: 'centered-text' },
    ],
  };
  const container = $('<div id="premium__loading">');
  const pApproved = premiumApproved();
  container.append(z2hApp.constructBlock(pApproved));
  container.append(z2hApp.constructBlock(title));

  return container;
};
//Build the html for registered lochy
const createPremiumRegistered = (_) => {
  const title = {
    template: 'block-info-text',
    selector: '',
    attributes: [
      { selector: '', type: 'innerHTML', content: strings.PREMIUM_LOADING_REGISTERED() },
      { selector: '', type: 'class', content: 'centered-text' },
    ],
  };
  const container = $('<div id="premium__loading">');
  const pApproved = premiumRegistered();
  container.append(z2hApp.constructBlock(pApproved));
  container.append(z2hApp.constructBlock(title));
  return container;
};

//Build the html for owned lochy
const createPremiumOwned = (_) => {
  const title = {
    template: 'block-info-text',
    selector: '',
    attributes: [
      { selector: '', type: 'innerHTML', content: strings.PREMIUM_LOADING_OWNED() },
      { selector: '', type: 'class', content: 'centered-text' },
    ],
  };
  const info = {
    template: 'block-info-text',
    selector: '',
    attributes: [
      { selector: '', type: 'innerHTML', content: strings.PREMIUM_LOADING_RELOAD() },
      { selector: '', type: 'class', content: 'centered-text' },
    ],
  };

  const container = $('<div id="premium__loading">');
  const pApproved = premiumOwned();
  container.append(z2hApp.constructBlock(pApproved));
  container.append(z2hApp.constructBlock(title));
  container.append(z2hApp.constructBlock(info));

  $('#premium__loading').remove();

  return container;
};

//Build the content box
const createPremiumContent = (product, productFromStore, localTransactions) => {
  //This box is a header and a bunch of list items
  //which are defined in productDetails array above
  let pItem;

  const pContent = premiumContent(product);
  pItem = z2hApp.constructBlock(pContent);

  for (const list of product.details) {
    const $li = $(`<li>${list.description}</li>`);
    pItem.find('ul').append($li);
  }
  //Build the correct button depending on the state of the
  //product

  console.log('product', productFromStore);

  const state = productFromStore.owned ? 'owned' : productFromStore.canPurchase ? 'valid' : 'invalid';

  const buttonDet = buttonDetails().find((b) => b.state === state);
  if (buttonDet) {
    const button = premiumButton({ ...product, ...buttonDet });
    const pButton = z2hApp.constructBlock(button);
    pItem.append(pButton);
  }

  return pItem;
};

//Causes the validator to be run
function verifyPurchase(transaction) {

  //We get a transaction for the app itself to prove it was downloaded from the app store, we're not going to deal with it. 
  if (transaction.products && transaction.products[0] && transaction.products[0].id === 'com.forghetti.forghettiapp') {
    transaction.finish();
    return;
  }

  //If the transaction is approved, we need to verify it
  if (transaction.state === 'approved') {
    transaction.verify();
  }
}

//All is valid, let's gooooo!
function finishPurchase(receipt) {
  // Once the transaction is approved, the product still isn't owned: the store needs confirmation
  // that the purchase was delivered before closing the transaction.
  if (canUpgrade) {
    window.state.launchData.home.premium = true;
    localStorage.setItem('launch', JSON.stringify(window.state.launchData));

    document.body.classList.add('premium');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  receipt.finish();

  refreshUI();
}

// End UI ====================================== //

//When the store has loaded
function onDeviceReady() {
  if (!window.cordova) return;

  if (initialized) {
    canUpgrade = false;
    refreshUI();
    return;
  }
  canUpgrade = false;

  initialized = true;

  //These are the products we're going to register
  const products = [
    {
      id: ANNUAL_SUBSCRIPTION_ID,
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: CdvPurchase.store.defaultPlatform(),
    },
    {
      id: MONTHLY_SUBSCRIPTION_ID,
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: CdvPurchase.store.defaultPlatform(),
    },
    // {
    //   id: LIFETIME_SUBSCRIPTION_ID,
    //   alias: strings.PREMIUM_ONE_OFF_ALIAS,
  ];

  CdvPurchase.store.register(products);

  //Each time a product is verified the validator is called.
  //This makes a request to forghetti server and checks the receipt
  CdvPurchase.store.validator = validator;

  CdvPurchase.store.error((err) => {
    console.error('store error', err);
  });

  CdvPurchase.store.verbosity = 3;
  CdvPurchase.store.when().updated(refreshUI).approved(verifyPurchase).verified(finishPurchase);

  CdvPurchase.store.initialize([CdvPurchase.store.defaultPlatform()]).then(() => {
    console.log('store initialized');
    refreshUI();
  });
  // refreshUI();
}

function restorePurchases() {
  return CdvPurchase.store.restorePurchases();
}

//TODO
function updatePremiumStatus(launchData) {
  console.log('');
}
function getSubscriptionType() {}

//Pop up the purchase thing from android/ios
function purchase(productId) {
  if (!CdvPurchase.store) return Promise.resolve();
  const offer = CdvPurchase.store.get(productId).getOffer();
  CdvPurchase.store.order(offer);
  return new Promise((resolve, reject) => {
    resolve();
  });
}

// =================================================================================================

function refreshUI() {
  console.log('Refresh UI');

  const subscriptions = CdvPurchase.store.products.filter((p) => p.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION);

  $('#premium__loading').remove();

  const hasPremium = $('#premium').find('.row.premium').length;
  if (!hasPremium) return;

  $('#premium').find('.row.premium').show();

  if (isOwned(subscriptions)) {
    //owned
    return $('#premium').find('.row.premium').hide().empty().parent().append(createPremiumOwned());
  } else if (isApproved(subscriptions)) {
    console.log('approved');
    return $('#premium').find('.row.premium').hide().empty().parent().append(createPremiumApproved());
  }

  const premium = $('#premium').find('.row.premium').hide().empty();

  //If we're still waiting for the store to approve the purchase
  // if (isInitiated(subscriptions)) {
  //   return $('#premium').find('.row.premium').hide().empty().parent().append(createPremiumRegistered());
  // }

  $('#premium .premium__tab-row').empty();
  $('#premium .premium__tab-container').empty();

  const $tabContainer = $('<div class="premium__tab-row"> </div>');
  const $detailContainer = $('<div class="premium__tab-container"> </div>');

  //Loop over the products and update the screen
  for (const p of CdvPurchase.store?.registeredProducts.list) {
    const productFromStore = CdvPurchase.store.get(p.id);
    if (!productFromStore) continue;
    const pDetail = productDetails().find((pd) => pd.id === productFromStore.id);
    productFromStore.header = pDetail.header;
    productFromStore.details = pDetail.details;
    productFromStore.tabHeader = pDetail.tabHeader;

    const localTransactions = CdvPurchase.store.findInLocalReceipts(productFromStore);
    $tabContainer.append(createPremiumTab(productFromStore));
    $detailContainer.append(createPremiumContent(productFromStore, productFromStore, localTransactions));
  }

  premium.append($tabContainer);
  premium.append($detailContainer);
  premium.show();
}

// Find a verified purchase for one of the provided products that passes the given filter.
function findVerifiedPurchase(products, filter) {
  for (const product of products) {
    const purchase = CdvPurchase.store.findInVerifiedReceipts(product);
    if (!purchase) continue;
    if (filter(purchase)) return purchase;
  }
}

// Find a local transaction for one of the provided products that passes the given filter.
function findLocalTransaction(products, filter) {
  // find if some of those products are part of a receipt
  for (const product of products) {
    const transaction = CdvPurchase.store.findInLocalReceipts(product);
    if (!transaction) continue;
    if (filter(transaction)) return transaction;
  }
}

function isOwned(products) {
  return !!findVerifiedPurchase(products, (p) => !p.isExpired);
}

function isApproved(products) {
  return !!findLocalTransaction(products, (t) => t.state === CdvPurchase.TransactionState.APPROVED);
}

function isInitiated(products) {
  return !!findLocalTransaction(products, (t) => t.state === CdvPurchase.TransactionState.INITIATED);
}

//Verify the receipts on the server!
function validator(receipt, callback) {
  console.log('validator for', receipt.id);
  const data = {
    platform: receipt.transaction.type === 'ios-appstore' ? 'apple' : 'google',
    receipt:
      receipt.transaction.type === 'ios-appstore'
        ? receipt.transaction.appStoreReceipt
        : receipt.transaction.purchaseToken,
    product_id: receipt.id,
  };

  let ownedProduct = null;
  let approvedProduct = null;

  console.log('receipt', receipt.type);

  //The application receipt is just to verify the app was downloaded from the app store, we don't need to do anything with it in forghetti. So we just say it's fine.
  // if (receipt.type === 'application') {
  //   callback({
  //     ok: true,
  //     data: {
  //       id: receipt.id,
  //       latest_receipt: true,
  //       transaction: receipt.transaction,
  //       collection: [{ id: receipt.id }],
  //     },
  //   });
  //   return;
  // }

  if (isOwned(receipt.products)) {
    ownedProduct = findVerifiedPurchase(receipt.products, (p) => !p.isExpired);
  }

  if (!ownedProduct) {
    if (isApproved(receipt.products)) {
      approvedProduct = findLocalTransaction(
        receipt.products,
        (t) => t.state === CdvPurchase.TransactionState.APPROVED,
      );
    }
  }

  if (approvedProduct) {
    data.product_id = approvedProduct.products[0].id;
    data.receipt = approvedProduct.parentReceipt.nativeData.appStoreReceipt;
  }

  if (ownedProduct) {
    data.product_id = ownedProduct.products[0].id;
    data.receipt = ownedProduct.parentReceipt.nativeData.appStoreReceipt;
  }

  console.log('approvedProduct', approvedProduct);
  console.log('ownedProduct', ownedProduct);

  //Make sure we've actually got a user!
  if (window.state.launchData.uid) {
    //Weird product ID bug?
    if (data.product_id === 'com.forghetti.forghettiapp') {
      callback({
        ok: true,
        data: {
          id: receipt.id,
          latest_receipt: true,
          transaction: receipt.transaction,
          collection: [{ id: receipt.id }],
        },
      });
      return;
    }

    z2hApp
      .requestData('post', '', 'users/@me/update-receipt', data)
      .then((response) => {
        let success = Math.floor(response.status / 100) === 2;

        const iapResponseSuccess = {
          ok: true,
          data: {
            id: receipt.id,
            latest_receipt: true,
            transaction: receipt.transaction,
            collection: [
              { id: receipt.id, isExpired: response?.error_code == 1700 ? true : false, expiryDate: Date.now() },
            ],
          },

          //response: response.data,
          //transaction: product.transaction,
        };
        const iapResponseError = {
          ok: false,
          data: { latest_receipt: true },
          code:
            response.error_code == 1700
              ? window.CdvPurchase.ErrorCode.VALIDATOR_SUBSCRIPTION_EXPIRED
              : window.CdvPurchase.ErrorCode.UNKNWON,
          message: response.user_message,
        };

        if (success && response.data && response?.error_code === 1700) {
        } else {
          success;
        }
        {
          if (!window.state.launchData.home.premium) {
            canUpgrade = true;
          }
        }

        callback(success ? iapResponseSuccess : iapResponseError);

        // if (!success && iapResponseError.code !== store.PURCHASE_EXPIRED) {
        //   showToastMessage(strings.SUBSCRIPTION_ERROR());
        //   z2hApp.paneNavigation('viewYourSubscription', $('#pane-2'), 1);
        // }
      })
      .catch((err) => {
        // const iapResponseError = {
        //   ok: false,
        //   data: { latest_receipt: true },
        //   code:
        //     response.error_code == 1700
        //       ? window.CdvPurchase.ErrorCode.VALIDATOR_SUBSCRIPTION_EXPIRED
        //       : window.CdvPurchase.ErrorCode.UNKNWON,
        //   message: response.user_message,
        // };

        // callback( iapResponseError );

        callback({
          ok: false,
          code: window.CdvPurchase.ErrorCode.BAD_RESPONSE,
          message: err,
          data: { latest_receipt: true },
        });
      });
  } else {
    callback({
      ok: false,
      code: window.CdvPurchase.ErrorCode.UNKNWON,
      message: 'some error',
      //transaction: product.transaction,
      data: { latest_receipt: true },
    });
  }
}

// =================================================================================================
// Event handlers

const { setOnAuthStateChanged } = require('../app/firebaseUtils');
//const { init } = require('logrocket');
let lastUser = null;
setOnAuthStateChanged((user, userChanged) => {
  if (!window.CdvPurchase) {
    return;
  }
  //wait until we have the user
  const hasUser = () => {
    // console.log(user);
    if (user && lastUser !== user.uid) {
      onDeviceReady();

      // const {
      //   store: cdvStore,
      //   ProductType: cdvProductType,
      //   Platform: cdvPlatform,
      //   TransactionState: cdvTransactionState,
      // } = CdvPurchase;

      store = CdvPurchase.store;
      ProductType = CdvPurchase.ProductType;
      Platform = CdvPurchase.Platform;
      TransactionState = CdvPurchase.TransactionState;

      lastUser = user.uid;
      return true;
    } else {
      setTimeout(() => {
        hasUser();
      }, 1000);
    }
  };

  if (user) {
    hasUser();
  } else {
    lastUser = null;
  }
});
// =================================================================================================

module.exports = {
  LIFETIME_SUBSCRIPTION_ID,
  MONTHLY_SUBSCRIPTION_ID,
  ANNUAL_SUBSCRIPTION_ID,
  getSubscriptionType,
  updatePremiumStatus,
  purchase,
  refreshUI,
  restorePurchases,
  onDeviceReady,
};
