{
  "name": "forghetti",
  "version": "3.6.0",
  "mobile_version": "3.5.7",
  "electron_version": "3.5.0",
  "extension_version": "3.5.0",
  "description": "Forghetti web application",
  "source": "index.html",
  "scripts": {
    "prestart": "node scripts/generateServiceWorker",
    "start:sync": "run-p start:sync:img start:sync:public",
    "start:sync:public": "cpx \"public/**/*.*\" \"dist/\" -w",
    "start:sync:img": "cpx \"img/**/*.*\" \"dist/img/\" -w",
    "start:bundler": " parcel index.html -p 8080",
    "start": "cross-env NODE_ENV=development run-p generateServiceWorker start:bundler start:sync ",
    "start:dev:localserver": "cross-env NODE_ENV=localserver run-p generateServiceWorker:localserver start:bundler start:sync",
    "start:dev": "cross-env NODE_ENV=development run-p generateServiceWorker:dev start:bundler start:sync",
    "start:devtest": "cross-env NODE_ENV=devtest run-p generateServiceWorker:devtest start:bundler start:sync",
    "start:test": "cross-env NODE_ENV=test run-p generateServiceWorker:test start:bundler start:sync",
    "start:prod": "cross-env NODE_ENV=production run-p generateServiceWorker:prod start:bundler start:sync",
    "start:copylive": "cross-env NODE_ENV=copylive run-p generateServiceWorker:copyLive start:bundler start:sync",
    "generateServiceIconList": "node scripts/generateServiceIconList",
    "generateGroupIconList": "node scripts/generateGroupIconList",
    "generateStrings": "node scripts/generateStringsJS",
    "generateServiceWorker": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.development",
    "generateServiceWorker:dev": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.development",
    "generateServiceWorker:cordova": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.cordova",
    "generateServiceWorker:cordova:test": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.cordovatest",
    "generateServiceWorker:devtest": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.devtest",
    "generateServiceWorker:localserver": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.localserver",
    "generateServiceWorker:test": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.test",
    "generateServiceWorker:prod": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.production",
    "generateServiceWorker:copylive": "node -r dotenv/config scripts/generateServiceWorker.js dotenv_config_path=.env.copylive",
    "copy:siteassoc": "cp -r public/.well-known dist/.well-known",
    "copy:public": "cpx \"public/**/*.*\" \"dist/\" && npm run copy:siteassoc",
    "copy:img": "cpx \"img/**/*.*\" \"dist/img/\"",
    "copy": "run-p generateStrings copy:public copy:img",
    "build": "rm -rf ./dist/*.* && npm run copy && npx parcel build --public-url . index.html extension.html && node scripts/fixSvgs",
    "build:cordova": "node scripts/populateCordovaVersion && npm run generateServiceWorker:cordova && cross-env-shell NODE_ENV=cordova npx parcel build --public-url . index.html && node scripts/fixSvgs",
    "build:cordova:test": "node scripts/populateCordovaVersion && npm run generateServiceWorker:cordova:test && cross-env-shell NODE_ENV=cordovatest npx parcel build --public-url . index.html && node scripts/fixSvgs",
    "build:electron": "node scripts/populateElectronVersion npm run generateServiceWorker:prod && rm -rf ./dist/*.* && npm run generateServiceWorker:prod && npm run copy && npx parcel build --public-url . index.html extension.html --no-source-maps && node scripts/fixSvgs",
    "build:test": "npm run generateServiceWorker:test && cross-env-shell NODE_ENV=test npm run build",
    "build:copylive": "npm run generateServiceWorker:copylive && cross-env-shell NODE_ENV=copylive npm run build",
    "build:localserver": "npm run generateServiceWorker:copylive && cross-env-shell NODE_ENV=localserver npm run build",
    "build:prod": "npm run generateServiceWorker:prod && rm -rf ./dist/*.* && npm run generateServiceWorker:prod && npm run copy &&  parcel build --public-url . index.html extension.html  && node scripts/fixSvgs",
    "deploy:test": "npm run generateServiceWorker:test && cross-env-shell NODE_ENV=test npm run build && firebase use test && firebase deploy",
    "deploy:copylive": "npm run generateServiceWorker:copylive && cross-env-shell NODE_ENV=copylive npm run build && firebase use copylive && firebase deploy",
    "deploy:prod": "npm run generateServiceWorker:prod && cross-env-shell NODE_ENV=production npm run build:prod && firebase use prod && firebase deploy",
    "deploy:preview": "npm run generateServiceWorker:prod && cross-env-shell NODE_ENV=production npm run build:prod && firebase use prod && firebase hosting:channel:deploy preview --expires 30d",
    "deploy": "npm run deploy:test"
  },
  "author": "Forghetti Limited",
  "license": "UNLICENSED",
  "devDependencies": {
    "@babel/core": "^7.19.3",
    "@babel/plugin-transform-runtime": "^7.19.1",
    "@parcel/packager-raw-url": "^2.8.0",
    "@parcel/transformer-less": "^2.8.0",
    "@parcel/transformer-webmanifest": "^2.8.0",
    "babel-plugin-transform-remove-console": "^6.9.4",
    "buffer": "^5.7.1",
    "cpx": "^1.5.0",
    "cross-env": "^7.0.3",
    "crypto-browserify": "^3.12.0",
    "csv-parser": "^3.0.0",
    "domain-browser": "^3.5.0",
    "eslint": "^8.24.0",
    "eslint-config-airbnb": "^17.1.0",
    "eslint-config-prettier": "^3.6.0",
    "eslint-plugin-import": "^2.14.0",
    "eslint-plugin-jsx-a11y": "^6.1.1",
    "eslint-plugin-prettier": "^3.0.0",
    "eslint-plugin-react": "^7.11.0",
    "events": "^3.3.0",
    "firebase-tools": "^13.6.1",
    "install": "^0.13.0",
    "less": "^3.9.0",
    "npm-run-all": "^4.1.5",
    "parcel": "^2.7.0",
    "parcel-plugin-bundle-visualiser": "^0.8.2",
    "posthtml-include": "^1.7.4",
    "prettier": "^2.7.1",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0",
    "util": "^0.12.5"
  },
  "dependencies": {
    "@babel/runtime-corejs2": "^7.20.6",
    "@lottiefiles/lottie-player": "^1.6.1",
    "@sentry/browser": "^7.23.0",
    "@sentry/tracing": "^7.23.0",
    "@skavinvarnan/cryptlib": "^1.0.3",
    "@wikimedia/jquery.i18n": "^1.0.7",
    "auto-bind": "^5.0.1",
    "banana-i18n": "^2.3.3",
    "bn.js": "^5.2.1",
    "browserslist": "^4.21.4",
    "caniuse-lite": "^1.0.30001036",
    "copy-html-to-clipboard": "^4.0.1",
    "cordova-plugin-firebase-dynamiclinks": "^7.0.2",
    "country-select-js": "^2.1.0",
    "croppie": "^2.6.5",
    "dialog-manager": "^1.0.3",
    "dom-to-image": "^2.6.0",
    "dotenv": "^16.0.3",
    "firebase": "^9.14.0",
    "fuzzball": "^2.1.2",
    "html2canvas": "^1.4.1",
    "intl-tel-input": "^17.0.19",
    "jquery": "^3.6.1",
    "jsencrypt": "^3.3.1",
    "lodash": "^4.17.21",
    "lottie-web": "^5.10.0",
    "md5": "^2.3.0",
    "owasp-password-strength-test": "^1.3.0",
    "parse-domain": "^7.0.1",
    "rasterizehtml": "^1.3.1",
    "req-all": "^1.0.0",
    "sanitize-html": "^2.7.3",
    "semver": "^7.3.8",
    "sha1": "^1.1.1",
    "slick-carousel": "^1.8.1",
    "tooltipster": "^4.2.8",
    "try-json": "^1.0.1",
    "webpack-bundle-analyzer": "^4.7.0"
  }
}
